










































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { IRelateColumnsAnswers, IRelateColumnsAlternative } from '@/globalInterfaces/Questions';

import {
  CLASS_CORRECT_QUESTION,
  CLASS_INCORRECT_QUESTION,
  MAXIMUM_FONT_SIZE,
  MINIMUM_FONT_SIZE,
} from '@/constant/Question';

@Component({
  components: {
    AlternativeIndex,
  },
  mixins: [LightBoxImage],
})
export default class AlternativeRelateColumnsResult extends Vue {
  @Prop({ default: 14 }) fontSizeProp!: number;

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  get shuffledAlternatives() {
    return this.$store.getters.shuffledAlternativesRelateColumns;
  }

  get shuffledResponses() {
    return this.$store.getters.answersRelateColumns;
  }

  setColor(answer: IRelateColumnsAnswers) {
    const alternativeFound: IRelateColumnsAlternative | undefined = this.shuffledAlternatives.find((alternative: IRelateColumnsAlternative) => alternative.ID === answer.optionID);

    if (!alternativeFound) return CLASS_INCORRECT_QUESTION;

    return alternativeFound.index === answer.selectedIndex ? CLASS_CORRECT_QUESTION : CLASS_INCORRECT_QUESTION;
  }

}
