
















import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import ExEditor from '@/components/ExEditor/index.vue';

@Component({
  components: {
    ExEditor,
  },
})
export default class SubjectiveQuestionInput extends Vue {
  @Prop({ default: false }) isResult!: boolean;

  get contentSubjectiveQuestion() {
    return this.$store.getters.contentSubjectiveQuestion;
  }

  saveAnswerToStore(content: string) {
    this.$store.commit('setContentSubjectiveQuestion', content);
  }
}
