

































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { CLASS_CORRECT_QUESTION, CLASS_INCORRECT_QUESTION } from '@/constant/Question';

@Component({
  components: {
    AlternativeIndex,
  },
  mixins: [LightBoxImage],
})
export default class AlternativeObjectiveResult extends Vue {
  @Prop({ required: true }) alternatives!: Array<Record<string, any>>;

  setColor(option: Record<string, any>) {
    let className = '';

    if (option?.correct === 1) {
      className = CLASS_CORRECT_QUESTION;
      return className;
    }

    if (option?.answerOptionID === option?.idOptions) {
      className = CLASS_INCORRECT_QUESTION;
    }

    return className;
  }

  optimizeImageLoadingInAlternative(alternative: string) {
    const newContentAlternative = alternative.replace('<img ', '<img loading="lazy" ');
    return newContentAlternative;
  }
}
