







































































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import LightBoxImage from '@/mixins/LightBoxImage';
import router from '@/router';

import RadioAlternativeResults from '@/components/Exercise/RadioAlternativeResults/RadioAlternativeResults.vue';
import SubjectiveQuestionInput from '@/components/Question/SubjectiveQuestionInput/index.vue';
import AlternativeObjectiveResult from '@/components/Question/AlternativeObjectiveResult/index.vue';
import AlternativeTruthyOrFalsyResult from '@/components/Question/AlternativeTruthyOrFalsyResult/index.vue';
import AlternativeSummationResult from '@/components/Question/AlternativeSummationResult/index.vue';
import AlternativeRelateColumnsResult from '@/components/Question/AlternativeRelateColumnsResult/index.vue';

import {
  TAG_TYPES, TAG_TYPE_MODEL, MAXIMUM_FONT_SIZE, MINIMUM_FONT_SIZE,
} from '@/constant/Question';

import { ITag } from '@/globalInterfaces/Questions';

const ROUTE_SIMULATED_QUESTIONS = 'SimulatedQuestions';
const ROUTE_SIMULATED_RESULT = 'SimulatedResult';
const ROUTE_ANSWER_QUESTION = 'AnswerQuestion';

@Component({
  components: {
    RadioAlternativeResults,
    AlternativeObjectiveResult,
    AlternativeTruthyOrFalsyResult,
    AlternativeSummationResult,
    AlternativeRelateColumnsResult,
    SubjectiveQuestionInput,
  },
  mixins: [LightBoxImage],
  filters: {
    modifierNumberPage(value: number): string {
      if (router.currentRoute.name === ROUTE_SIMULATED_QUESTIONS || router.currentRoute.name === ROUTE_SIMULATED_RESULT) {
        return (value < 10) ? `0${value}` : `${value}`;
      }

      return (value < 9) ? `0${value + 1}` : `${value + 1}`;
    },
  },
})
export default class Question extends Vue {
  @Prop({ required: true }) question!: Record<string, any>;
  @Prop({ default: 0 }) indexQuestion!: number;
  @Prop({ default: 16 }) fontSizeProp!: number;
  @Prop({ default: '' }) model!: string;

  private TAG_TYPE_MODEL = TAG_TYPE_MODEL;

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  get isAnswerQuestion() {
    return this.$route.name === ROUTE_ANSWER_QUESTION;
  }

  get testBoard() {
    if (!this.isAnswerQuestion) return '';

    const tag = this.getTag(this.question.tags, TAG_TYPES.TEST_BOARD);

    return tag?.name || '';
  }

  get application() {
    if (!this.isAnswerQuestion) return '';

    const tag = this.getTag(this.question.tags, TAG_TYPES.APPLICATION);

    return tag?.name || '';
  }

  get year() {
    if (!this.isAnswerQuestion) return '';

    const tag = this.getTag(this.question.tags, TAG_TYPES.YEAR);

    return tag?.name || '';
  }

  get questionApplication() {
    if (!this.isAnswerQuestion) return '';

    const application = `
      ${this.testBoard ? `${this.testBoard} ` : ''}${this.year ? `${this.year} ` : ''}${this.application ? `${(this.testBoard.length ? this.testBoard : this.year) ? '- ' : ''}${this.application}` : ''}
    `;

    return application.trim();
  }

  optimizeImageLoadingInQuestion(question: string) {
    const newContentQuestion = question.replace('<img ', '<img loading="eager" ');
    return newContentQuestion;
  }

  getTag(tags: ITag[], tagType: number) {
    return tags.find((tag) => tag.tagTypesID === tagType);
  }
}
